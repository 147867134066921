@import 'react-toastify/dist/ReactToastify.min.css';
body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.div-a{
  cursor: pointer;
}
.nav-link p{
  color: #c2c7d0;
}
div.active.nav-link p,.dashboard{
  color:white !important
}
a.active.nav-link p{
  color:#2e2e2e
}

table thead tr th{
  max-width: 120px;
  text-align: center;
}
td{
  text-align: center;
}
.table-bordered th, .table-bordered td {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
}
tfoot tr th input{
  width: 100%;
  text-align: center;
  outline: none;
  border-color: inherit;
  border: transparent;
}
tfoot tr th{
  padding: 3px !important;
  text-align: center;
}
.grid-2{
 display: grid;
 grid-template-columns: 1fr 1fr;
 gap: 5px;
}
.grid-2 .left{
  text-align: left;
}
.grid-2 .right{
  text-align: right;
}
.grid-6{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
 }
 .tab-content .tab-loading{
  position: relative !important;
 }
 .text-unit{
  font-size: 24px;
 }

 ul, li, ul li {
  list-style-type: none !important;
}
.font-size {
  font-size: 28px;
}
.form-check-input {
  height: 20px;
  width: 20px;
}
.form-check {
  padding-top: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-label {
  text-transform: uppercase;
  margin-left: 5px;
  padding-top: 2px;
  font-size: 20px;
}
.form-check i {
  padding-right: 30px;
}
.under {
  padding-left: 5em !important;
}
.form-check i, .form-check input {
  cursor: pointer;
}
.hide {
  opacity: 0;
  height: 0 !important;
  display: none !important;
}
.nav-item{
  border-bottom :unset !important
}
@media screen and (max-width: 768px) {
  .card-title {
      font-size: 0.9rem;
  }
  .grid-6{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 10px;
   }
}