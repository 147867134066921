body{
    background-color: black;
}
.gAZQBi {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(https://cdna.artstation.com/p/assets/images/images/010/959/642/large/guo-yunhong-.jpg);
    opacity: 0.3;

}
.logo{
    text-align: center
}
.logo img {
    width: 250px;
    margin-top:.5rem
}
.row-grid-2{
    display: grid;
    grid-template-columns: 100%;
}
.companyname{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
}
.block-banner{
    border-radius: 0.5rem;
    overflow: hidden;
    border: 2px solid rgb(255, 255, 255);
    /* box-shadow: 0px 0px 15px rgb(255, 208, 0) */
    box-shadow: 0px 0px 15px #33a4cb
    /* box-shadow: 0 0 5px #2c83a9, 0 0 15px #05e5fb, 0 0 15px #59c9ff; */
}
.swiper-slide{
    width: 100%;
}
.swiper-slide img{
    width: 100%;
    display: block;
    border-radius: 0.5rem;
}
.demo-game .swiper-slide {
    border-radius: 0.5rem;
    overflow: hidden;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 0px 15px #33a4cb
    /* box-shadow: 0 0 5px #2c83a9, 0 0 15px #05e5fb, 0 0 15px #59c9ff; */
}
iframe{
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 0px 15px #33a4cb;
    border-radius: 0.5rem;
}
footer .footer-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 338px;
    padding: 30px 0 30px 360px;
    position: relative;
    z-index: 2;
}
.grid-footer-2 {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 5px;
}
.grid-footer-2 .div-logo{
   text-align: center;
   position: relative;
}
.footer-logo{
    width: 200px;
    /* height: 200px; */
    top: 25%;
    position: relative;
}

.provider-list-box {
    margin-bottom: 30px;
}
.provider-list-box {
    display: flex;
    flex-wrap: wrap;
}
.provider-list-box div {
    margin-bottom: 5px;
    margin-right: 10px;
}
.provider-list-box div img {
    display: block;
    height: 25px;
    margin: 0;
    width: auto;
}


@keyframes aqm {
    0% {
        filter: drop-shadow(0 2px 8px rgba(0,0,0,.8))
    }

    55% {
        filter: drop-shadow(0 2px 6px #fddc94)
    }

    70% {
        filter: drop-shadow(0 2px 6px #fee5ad)
    }

    to {
        filter: drop-shadow(0 2px 8px rgba(0,0,0,.8))
    }
}
.event-qmenu.menu-close {
    transform: translateX(-83px)
}
.event-qmenu {
    animation: aqm 1.8s ease infinite;
    /* bottom: 10px; */
    bottom: 50%;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, .8));
    left: 0;
    min-height: auto !important;
    position: fixed;
    transition: .3s ease;
    width: 110px;
    z-index: 21;
}
.event-qmenu.menu-close .handle:after {
    left: -13px;
    text-shadow: 0 1px 3px #fbb417;
    transform: translateY(-50%) rotate(0deg)
}

.event-qmenu .handle {
    bottom: 5px;
    cursor: pointer;
    position: absolute;
    right: 2px;
    width: 30px;
}
.event-qmenu .handle:after {
    speak: none;
    color: #fff;
    content: "";
    font-family: iconmps!important;
    font-size: 55px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    left: 8px;
    line-height: 1;
    position: absolute;
    text-align: center;
    text-shadow: 0 -1px 3px #fbb417;
    text-transform: none;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 30px
}
.event-qmenu .handle path {
    fill: #fcc449
}
.event-qmenu .cont {
    align-items: center;
    background-color:rgb(4 50 91 / 0%);
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 0px 15px #33a4cb;
    border-bottom-right-radius: 15px;
    border-left: 0;
    border-top-right-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    min-height: 103px;
    padding: 10px;
    width: 80px;
}
.event-qmenu .cont .event-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    margin: 5px 0;
    position: relative;
    transition: .3s ease;
    width: 60px;
}

.event-qmenu .cont .event-item img {
    display: block;
    max-height: 100%;
    max-width: 100%;
}
.event-qmenu .cont .event-item:hover {
    transform: scale(1.2)
}

@media screen and (max-width: 1000px) {
    .logo img {
        width: 210px;
        margin-top:.5rem
    }
    .companyname{
        font-size: 20px;
    }
}
@media screen and (max-width: 768px) {
    .logo img {
        width: 180px;
        margin-top:.5rem
    }
    .companyname{
        font-size: 15px;
    }
    .grid-footer-2 {
        display: grid;
        grid-template-columns: 100%;
        gap: 5px;
    }
    .grid-footer-2 .div-logo{
        text-align: center;
        position: unset !important;
     }
     .footer-logo{
         width: 200px;
         top: unset;
         position: unset !important;
     }
}
@media screen and (max-width: 576px) {
    .event-qmenu .cont .event-item {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        margin: 5px 0;
        position: relative;
        transition: .3s ease;
        width: 40px;
    }
    .event-qmenu .cont {
        align-items: center;
        background-color: rgb(4 50 91 / 0%);
        border: 3px solid #fcc449;
        border-bottom-right-radius: 15px;
        border-left: 0;
        border-top-right-radius: 15px;
        display: flex;
        flex-wrap: wrap;
        min-height: 103px;
        padding: 10px;
        width: 50px;
    }
}

.bubbles{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index:0;
    overflow:hidden;
    top: 0;
    left: 0;
  }
  .bubble{
    position: absolute;
    bottom: -100px;
    background-color: #f1f1f1;
    border-radius:50%;
    opacity:0.5;
    animation:fly 15s ease-in-out infinite;
  }
  .bubble:nth-child(1){
    left: 1%;
    height: 40px;
    width: 40px;
    animation-duration:8s;
    
  }
  .bubble:nth-child(2){
    left: 10%;
    height: 20px;
    width: 20px;
    animation-duration:10s;
    animation-delay:1s;
  }
  .bubble:nth-child(3){
    left: 15%;
    height: 30px;
    width: 30px;
    animation-duration:6s;
    animation-delay:2s;
  }
  .bubble:nth-child(4){
    left: 30%;
    height: 60px;
    width: 60px;
    animation-duration:12s;
    animation-delay:5s;
  }
  .bubble:nth-child(5){
    left: 40%;
    height: 30px;
    width: 30px;
    animation-duration:9s;
    animation-delay:0;
  }
  .bubble:nth-child(6){
    left: 50%;
    height: 15px;
    width: 15px;
    animation-duration:13s;
    animation-delay:8s;
  }
  .bubble:nth-child(7){
    left: 55%;
    height: 60px;
    width: 60px;
    animation-duration:10s;
    animation-delay:0;
  }
  .bubble:nth-child(8){
    left: 60%;
    height: 10px;
    width: 10px;
    animation-duration:14s;
    animation-delay:5s;
  }
  .bubble:nth-child(9){
    left: 65%;
    height: 20px;
    width: 20px;
    animation-duration:10s;
    animation-delay:3s;
  }
  .bubble:nth-child(10){
    left: 70%;
    height: 55px;
    width: 55px;
    animation-duration:7s;
    animation-delay:11s;
  }
  .bubble:nth-child(11){
    left: 80%;
    height: 33px;
    width: 33px;
    animation-duration:4s;
    animation-delay:15s;
  }
  .bubble:nth-child(12){
    left: 90%;
    height: 24px;
    width: 24px;
    animation-duration:8s;
    animation-delay:5s;
  }
  .bubble:nth-child(13){
    left: 95%;
    height: 20px;
    width: 20px;
    animation-duration:9s;
    animation-delay:4s;
  }
  .bubble:nth-child(14){
    left: 25%;
    height: 28px;
    width: 28px;
    animation-duration:17s;
    animation-delay:0s;
  }
  .bubble:nth-child(15){
    left: 20%;
    height: 20px;
    width: 20px;
    animation-duration:14s;
    animation-delay:1s;
  }
  
  @keyframes fly{
    0%{
      bottom: -100px;
      transform:translateX(0);
    }
    50%{
      transform:translateX(100px);
    }
    
    100%{
      bottom: 1080px;
      transform:translateX(-200px);
    }
    
    
  }